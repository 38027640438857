<nz-layout>
  <nz-sider
    nzCollapsible
    nzBreakpoint="lg"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    [nzWidth]="250"
    class="main-sidebar"
  >
    <div class="site-logo">
      <img
        [class.collapse-logo]="isCollapsed"
        class="logo"
        [src]="
          isCollapsed
            ? '../../.././../assets/images/Logo-Mark-SVG.svg'
            : '../../.././../assets/images/Green-Black-Logo.svg'
        "
      />
    </div>

    <ul nz-menu nzTheme="dark" nzMode="inline" class="sidemenu">
      <ng-container *ngFor="let menu of sideMenuItems">
        <ng-template [ngxPermissionsOnly]="menu.role">
          <li
            *ngIf="!menu.children"
            nz-menu-item
            [nzPaddingLeft]="menu.level * 24"
            [nzDisabled]="menu.disabled"
            [nzMatchRouter]="menu.route"
            [routerLink]="menu.route"
          >
            <i nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
            <span>{{ menu.title }}</span>
          </li>

          <li
            *ngIf="menu.children"
            nz-submenu
            [nzPaddingLeft]="menu.level * 24"
            [nzOpen]="false"
            [nzTitle]="menu.title"
            [nzIcon]="menu.icon"
            [nzDisabled]="menu.disabled"
          >
            <ul *ngFor="let child of menu.children">
              <li
                [ngClass]="{
                  notMatchRoute: child.matchRoutes.includes(currentRoute)
                }"
                [nzMatchRouter]="child.route"
                [routerLink]="child.route"
                [nzSelected]="true"
                [nzMatchRouterExact]="true"
                nz-menu-item
              >
                <i nz-icon [nzType]="child.icon" *ngIf="child.icon"></i>
                <span>{{ child.title }}</span>
              </li>
            </ul>
          </li>
        </ng-template>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout class="main-body-wrap">
    <nz-header>
      <ul nz-menu nzMode="horizontal" class="header-menu">
        <li nz-menu-item class="sidebar-trigger">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="isCollapsed = !isCollapsed"
          ></i>
        </li>
        <li class="header-user">
          <a nz-dropdown [nzDropdownMenu]="menu">
            <span><i nz-icon nzType="user" nzTheme="outline"></i></span>
            {{ loginUserName | titlecase }}
          </a>
        </li>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable class="header-dropdown">
            <li
              nz-menu-item
              *ngIf="isAuth"
              (click)="onLogout()"
              class="log-out"
            >
              <span
                ><span nz-icon nzType="logout" nzTheme="outline"></span></span
              >Log Out
            </li>
          </ul>
        </nz-dropdown-menu>
      </ul>
    </nz-header>
    <nz-content class="main-body">
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>©2022 eSparkbiz Exam Portal</nz-footer>
  </nz-layout>
  <!-- <div class="screen-msg">
        <span class="screen-msg-des">Please view website IPAD and above Screen</span>
    </div> -->
</nz-layout>
