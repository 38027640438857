import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading = new Subject<boolean>();

  constructor() {
  }

  /**
   * Show loader
   */
  showLoader() {
    this.loading.next(true);
  }

  /**
   * Hide loader
   */
  hideLoader() {
    this.loading.next(false);
  }
}
