import { NgxPermissionsModule } from 'ngx-permissions';
import { LoadingComponent } from './shared/loading/loading.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './core/layout/navigation/navigation.component';
import { SharedModule } from './shared/shared.module';
import { SideNavigationComponent } from './core/layout/side-navigation/side-navigation.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SideNavigationComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NzModalModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxWebstorageModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxUiLoaderModule, 
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})
export class AppModule { }
