import { TokenService } from './../services/token.service';
import { ToastService } from './../services/toast.service';
import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { LocalStorageService } from 'ngx-webstorage';
export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()

export class LoadingInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService, private storage: LocalStorageService, private loadingService: LoadingService, private authService: AuthService, private router: Router, private toastService: ToastService) { }


  /**
   * Each and every api call loaded by the loading interceptor
   * @param req 
   * @param next 
   * @returns 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const access_token = this.tokenService.getToken();
    if (req.context.get(BYPASS_LOG) === true)
    {
      if (access_token) {
        req = req.clone({
          headers: req.headers.set('Authorization', "Bearer " + access_token)
        })
      }      
      return next.handle(req);
    }
    this.loadingService.showLoader();
    if (access_token) {
      req = req.clone({
        headers: req.headers.set('Authorization', "Bearer " + access_token)
      })
    }
    return next.handle(req).pipe(
      finalize(() => this.loadingService.hideLoader())
    );
  }
}
