import { NzFormModule } from 'ng-zorro-antd/form';
import { ErrorHandlerInterceptor } from './interceptors/error.handler.interceptor';
import { LoadingInterceptor } from './interceptors/loading-interceptor.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';



const nzModules = [
  NzFormModule,
  NzPageHeaderModule,
  NzLayoutModule,
  NzInputModule,
  NzButtonModule,
  NzCardModule,
  NzMenuModule,
  NzDropDownModule,
  NzIconModule,
  NzTableModule,
  NzPaginationModule,
  NzSpaceModule,
  NzAlertModule,
  NzMessageModule,
  NzGridModule,
  NzSelectModule,
  NzPopconfirmModule,
  NzRadioModule,
  NzCheckboxModule,
  NzListModule,
  NzInputNumberModule,
  NzBackTopModule,
  NzToolTipModule,
  NzStatisticModule,
  NzResultModule,
  NzModalModule,
  NzSwitchModule
]
@NgModule({
  imports: [
    ...nzModules,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...nzModules,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true }],
})
export class SharedModule { }
