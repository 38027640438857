import { Observable } from 'rxjs';
import { UserLoginRequest, UserRegistrationRequest, UserLoginResponse, UserRegistrationResponse, LogOutResponse, ForgotPasswordResponse, ResetPasswordResponse, ResetPasswordRequest } from './../models/auth.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    baseUrl = environment.baseUrl;
    constructor(
        private httpClient: HttpClient
    ) { }

    /**
     * Login data
     * @param data 
     * @returns 
     */
    loginUserData(data: UserLoginRequest): Observable<UserLoginResponse> {
        return this.httpClient.post<UserLoginResponse>(this.baseUrl + 'login', data)
    }

    /**
     * Register user data 
     * @param data 
     * @returns 
     */
    registerUserData(data: UserRegistrationRequest): Observable<UserRegistrationResponse> {
        return this.httpClient.post<UserRegistrationResponse>(this.baseUrl + 'register', data)
    }

    /**
     * Logout 
     * @returns 
     */
    logout(): Observable<LogOutResponse> {
        return this.httpClient.get<LogOutResponse>(this.baseUrl + 'logout');
    }

    /**
     * Forgot password 
     * @param data 
     * @returns 
     */
    forgotPassword(data: string): Observable<ForgotPasswordResponse> {
        return this.httpClient.post<ForgotPasswordResponse>(this.baseUrl + 'forgot-password', data);
    }

    /**
     * Reset password
     * @param data 
     * @returns 
     */
    resetPassword(data: ResetPasswordRequest): Observable<ResetPasswordResponse> {
        return this.httpClient.post<ResetPasswordResponse>(this.baseUrl + 'reset-password', data);
    }
}