import { Subject } from 'rxjs';
import { LoadingService } from './../services/loading.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  constructor(
    private loadingService: LoadingService
  ) { }
  isLoading: Subject<boolean> = this.loadingService.loading;
  ngOnInit(): void { }
}
