import { Roles } from './shared/enums/roles.enum';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './core/layout/navigation/navigation.component';
import { SideNavigationComponent } from './core/layout/side-navigation/side-navigation.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
    ]
  },
  {
    path: 'user',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'exam',
        loadChildren: () => import('./modules/user-exam/user-exam.module').then(m => m.UserExamModule)
      },
    ]

  },
  {
    path: 'exam-portal',
    component: SideNavigationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [Roles.Admin,Roles.SuperAdmin],
             redirectTo: '/exam-portal/dashboard'
          }
        },
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'user',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: Roles.User,
          }
        },
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
