import { Roles } from './../../../shared/enums/roles.enum';
import { TokenService } from './../../../shared/services/token.service';
import { ActivatedRoute, NavigationStart,Router } from '@angular/router';
import { AuthService } from './../../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter } from 'rxjs';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit{

  isCollapsed: boolean = false;
  ifMatched: boolean = true;
  currentRoute: any;

  constructor(private authService: AuthService,
    public router: Router,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private permissionService: NgxPermissionsService) {
    if(router.url.includes('/exam-portal/admin/exam/edit')){
      this.currentRoute = '/exam-portal/admin/exam/edit';
    }
    if(router.url.includes('/exam-portal/admin/question-category/edit')){
      this.currentRoute = '/exam-portal/admin/question-category/edit';
    }
    if(router.url.includes('/exam-portal/admin/questions/edit')){
      this.currentRoute = '/exam-portal/admin/questions/edit';
    }
    if(router.url.includes('exam-portal/admin/questions/view')){
      this.currentRoute = 'exam-portal/admin/questions/view';
    }
    if(router.url.includes('/exam-portal/admin/exam/result')){
      this.currentRoute = '/exam-portal/admin/exam/result';
    }
    
    router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.currentRoute = event;
        this.currentRoute = this.currentRoute.url;

        if( this.currentRoute.includes('/exam-portal/admin/exam/edit')){
          this.currentRoute = '/exam-portal/admin/exam/edit';
        }
        if( this.currentRoute.includes('/exam-portal/admin/question-category/edit')){
          this.currentRoute = '/exam-portal/admin/question-category/edit';
        }
        if( this.currentRoute.includes('/exam-portal/admin/questions/edit')){
          this.currentRoute = '/exam-portal/admin/questions/edit';
        }
        if( this.currentRoute.includes('exam-portal/admin/questions/view')){
          this.currentRoute = 'exam-portal/admin/questions/view';
        }
        if(this.currentRoute.includes('/exam-portal/admin/exam/result')){
          this.currentRoute = '/exam-portal/admin/exam/result';
        }
      });
    
  }
  id:number;
  userParamsId: {id: string};
  token = this.tokenService.getToken();
  isAuth = this.token ? true : false;
  loginUserName:string;
  ngOnInit(): void {
    let loginData = JSON.parse(localStorage.getItem('ngx-webstorage|user')|| '');
    this.loginUserName = loginData.name;
  }

  /**
   * Function for logout
   */
  onLogout() {
    this.authService.logout()
      .subscribe(
        res => {
          this.tokenService.clearlsData();
          this.router.navigate(['/login']);
        },
        error => {
          console.log(error);
        }
      )
  }

  sideMenuItems: any[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      role: [Roles.Admin, Roles.User,Roles.SuperAdmin],
      route: '/exam-portal/dashboard',
      matchRoutes: ['/exam-portal/admin/dashboard']
    },
    {
      title: 'Exams',
      icon: 'book',
      role: [Roles.Admin,Roles.SuperAdmin],
      children: [
        {
          title: 'List Exams',
          icon: 'ordered-list',
          route: '/exam-portal/admin/exam',
          matchRoutes: ['/exam-portal/admin/exam', '/exam-portal/admin/exam/result']
        },
        {
          title: 'Create Exam',
          icon: 'plus-square',
          route: '/exam-portal/admin/exam/add',
          matchRoutes: ['/exam-portal/admin/exam/add','/exam-portal/admin/exam/edit']
        }
      ]
    },
    {
      title: 'Candidate Category',
      icon: 'group',
      role: [Roles.Admin,Roles.SuperAdmin],
      children: [
        {
          title: 'Colleges',
          icon: 'ordered-list',
          route: '/exam-portal/admin/candidate-category',
          matchRoutes: ['/exam-portal/admin/candidate-category']
        },
      ]
    },
    {
      title: 'Question Category',
      icon: 'unordered-list',
      role: [Roles.Admin,Roles.SuperAdmin],
      children: [
        {
          title: 'List',
          icon: 'ordered-list',
          route: '/exam-portal/admin/question-category',
          matchRoutes: ['/exam-portal/admin/question-category']
        },

        {
          title: 'Add Category',
          icon: 'plus-square',
          route: '/exam-portal/admin/question-category/add',
          matchRoutes: ['/exam-portal/admin/question-category/add','/exam-portal/admin/question-category/edit']
        }

      ]
    },
    {
      title: 'Questions',
      icon: 'question',
      role: [Roles.Admin,Roles.SuperAdmin],
      children: [
        {
          title: 'List',
          icon: 'ordered-list',
          route: '/exam-portal/admin/questions',
          matchRoutes: ['/exam-portal/admin/questions', 'exam-portal/admin/questions/view']
        },
        {
          title: 'Add Question',
          icon: 'plus-square',
          route: '/exam-portal/admin/questions/add',
          matchRoutes: ['/exam-portal/admin/questions/add','/exam-portal/admin/questions/edit']
        }
      ]
    },
    {
      title: 'Administrator',
      icon: 'user',
      role: [Roles.SuperAdmin],
      children: [
        {
          title: 'List',
          icon: 'ordered-list',
          route: '/exam-portal/admin/administrator',
          matchRoutes: ['/exam-portal/admin/administrator']
        },
      ]
    },
    {
      title: 'Exam Instruction',
      icon: 'info',
      role: [Roles.Admin,Roles.SuperAdmin],
      route: '/exam-portal/admin/exam-instruction',
      matchRoutes: ['/exam-portal/admin/exam-instruction']

    },

    // {
    //   title: 'Settings',
    //   icon: '',
    //   children: [
    //     {
    //       title: 'Site Settings',
    //       icon: '',
    //     }
    //   ]
    // },
    // {
    //   title: 'Users',
    //   icon: '',
    //   children: [
    //     {
    //       title: 'User List',
    //       icon: '',
    //     }
    //   ]
    // }
  ]
}
