import { QuestionDetails } from './../models/user-exam.model';
import { User } from './../models/auth.model';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor(
        private storage: LocalStorageService
    ) { };

    /**
     * In the local storage, store the token, user and role
     * @param token 
     * @param user 
     * @param role 
     */
    setlsData(token: string, user: User, role: string): void {
        this.storage.store('access_token', token);
        this.storage.store('user', user);
        this.storage.store('role', role);
    }
    
    /**
     * In the local storage, retrieve token
     * @returns 
     */
    getToken(): string {
        return this.storage.retrieve('access_token');
    }

    /**
     * In the local storage, retrieve hastoken
     * @returns 
     */
    hasToken(): boolean {
        const token = this.storage.retrieve('access_token');
        return token !== null && token !== undefined && token !== '';
    }

    /**
     * In the local storage, retrieve user
     * @returns 
     */
    getUser(): User {
        return this.storage.retrieve('user')
    }

    /**
     * In the local storage, retrieve user role
     * @returns 
     */
    getRole(): string {
        return this.storage.retrieve('role');
    }

    /**
     * In the local storage, clear access_token, user and role data 
     */
    clearlsData(): void {
        this.storage.clear('access_token');
        this.storage.clear('user');
        this.storage.clear('role');
        this.storage.clear('category_list');
        this.storage.clear('QuestionData');
        this.storage.clear('candidate_id');
        this.storage.clear('exam_id');
        this.storage.clear('flag');
        this.storage.clear('status');
        this.storage.clear('focus_val');
    }

    /**
     * In the local storage, store exam question status 
     * @param data 
     */
    setExamQuestionStatus(data:QuestionDetails[]){
        this.storage.store('category_list', data);
    }

    /**
     * In the local storage, retrieve exam question status
     * @returns 
     */
    getExamQuestionStatus(){
        return this.storage.retrieve('category_list');
    }
}