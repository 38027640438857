import { NgxPermissionsService } from 'ngx-permissions';
import { TokenService } from './../services/token.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(private tokenService: TokenService,
        private router: Router,
        private permissionService: NgxPermissionsService
    ) { }

    /**
     * Canactive guard for check user active or not
     * @param route 
     * @param state 
     * @returns 
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const isAuthorized = this.tokenService.hasToken();
        const roles = [this.tokenService.getRole()];
        this.permissionService.loadPermissions(roles);
        if (isAuthorized) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}