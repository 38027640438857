import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(
        private message: NzMessageService
    ) { }

    /**
     * Error messages
     * @param message 
     */
    errorMessage(message: string) {
        const messageConfig = {
            nzDuration: 2000,
            nzPauseOnHover: true,
            nzAnimate: true
        }
        this.message.error(message, messageConfig);
    }

    /**
     * Success messages
     * @param successMessage 
     */
    successMessage(successMessage: string) {
        const messageConfig = {
            nzDuration: 2000,
            nzPauseOnHover: true,
            nzAnimate: true
        }
        this.message.success(successMessage, messageConfig);
    }
}