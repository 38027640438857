import { TokenService } from './../services/token.service';
import { Router } from '@angular/router';
import { ToastService } from './../services/toast.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()

export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastService,
        private router: Router,
        private tokenService: TokenService) { }



    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                event => {
                    try {
                        if (event instanceof HttpResponse) {
                            if (event.body && event.body.status && event.body.toast) {
                                this.toastService.successMessage(event.body.message);
                            }
                        }
                    } catch (error) {
                        this.toastService.errorMessage("somthing went wrong");
                    }
                }
            ),
            catchError((error: HttpErrorResponse) => {
                try {
                    if (error.status == 401 || error.status == 406) {
                        this.tokenService.clearlsData();
                        this.router.navigate(['/login']);
                    } if (error.status == 500 || error.status == 0) {
                        this.toastService.errorMessage("somthing went wrong");
                    } if(error.status == 403){
                        this.router.navigate(['/exam-portal/dashboard']);
                    }else if (error.error) {
                        this.toastService.errorMessage(error.error.message);
                    }
                } catch (error) {
                    this.toastService.errorMessage("somthing went wrong");
                }
                return throwError(error);
            })
        );
    }
}
